import React, { useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
    CircularProgress,
    Autocomplete
} from "@mui/material";
import { isEmpty } from "../../../Utils/GeneralUtils";
import {
    get12MonthsAheadAnd1MonthBackArr,
    getMonthsArray
} from "../../../Utils/JobHelpers";

const EditRetainerJobConfirmation = (props) => {
    const {
        loading = false
    } = props;
    const [inputValue, setInputValue] = useState("");
    const [retainerStartDateDisplay, setRetainerStartDateDisplay] = useState(null);
    const [retainerStartDate, setRetainerStartDate] = useState(null);
    const [retainerDurationDisplay, setRetainerDurationDisplay] = useState({
        id: 1,
        value: `1 Month`,
    });
    const [retainerDuration, setRetainerDuration] = useState(0);

    return (
        <div>
            <Dialog
                open={props.open}
                onClose={(e, reason) => {
                    if (reason !== "backdropClick") {
                        props.handleClose()
                    }
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                classes={{ paper: "confirmation-with-input-dialog" }}
            >
                <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.description}
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label={props.textFieldLabel}
                        type="text"
                        fullWidth
                        variant="filled"
                        multiline
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                    />
                    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                        <div style={{ width: "50%", marginRight: 2 }}>
                            <Autocomplete
                                value={retainerStartDateDisplay}
                                options={get12MonthsAheadAnd1MonthBackArr()}
                                onChange={(event, option) => {
                                    setRetainerStartDateDisplay(option);
                                    setRetainerStartDate(option.value);
                                }}
                                getOptionLabel={(option) => option.display}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={"Start Billing From"}
                                        size="small"
                                        fullWidth={true}
                                        variant="filled"
                                    />
                                )}
                                disableClearable
                            />
                        </div>
                        <div style={{ width: "50%", marginLeft: 2 }}>
                            <Autocomplete
                                value={retainerDurationDisplay}
                                options={getMonthsArray(12)}
                                onChange={(event, option) => {
                                    setRetainerDuration(option.id);
                                    setRetainerDurationDisplay(option);
                                }}
                                getOptionLabel={(option) => option.value}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label={`Retainer Duration`}
                                        size="small"
                                        fullWidth={true}
                                        variant="filled"
                                    />
                                )}
                                disableClearable
                            />
                        </div>
                    </div>
                </DialogContent>
                {loading ? (
                    <DialogActions>
                        <CircularProgress />
                    </DialogActions>
                ) : (
                    <DialogActions>
                        <Button disabled={loading} className="default-button" variant="contained" onClick={props.handleClose}>Cancel</Button>
                        <Button
                            disabled={isEmpty(inputValue) || loading || isEmpty(retainerDuration) || isEmpty(retainerStartDate)}
                            className={isEmpty(inputValue) || isEmpty(retainerDuration) || isEmpty(retainerStartDate) ? "" : "default-button"}
                            variant="contained"
                            onClick={() => {
                                props.handleSubmit(inputValue, retainerStartDate, retainerDuration)
                            }}
                            autoFocus>
                            {props.submitLabel}
                        </Button>
                    </DialogActions>
                )}
            </Dialog>
        </div>
    );
};

export default EditRetainerJobConfirmation;
